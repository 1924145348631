<template>
  <div class="wood-background">
    <card-detail
        v-if="selectedCard"
        :card="selectedCard"
        @navigateCard="navigateCard($event)"
        @close="selectCard(null)"/>
    <players
        class="players-panel hud-pane"
        @added="$router.push({name: 'addToPile2'})"
        @discardSelected="discardSelected"/>
    <score
        class="score hud-pane"/>
    <div
        class="content-wrapper">
      <div class="content">
        <pile
            v-if="cardsInSelectedPile.length > 0"
            :cards="cardsInSelectedPile"
            class="pile-area"
            :show-scoring-info="selectedPile > 0"
            @edit="$router.push({name: 'editRelation', params: {cardName: $event.name}})"
            @remove="resetCard($event.name)"
            @select="selectCard($event)"
            @editRelation="$router.push({name: 'editRelation', params: {cardName: $event.name}})"/>
        <div
            v-else-if="selectedPlayer && selectedPlayer.number > 0">
          <span>{{ $t('addCardsToPlayerHand', {player: selectedPlayer.name}) }}</span>
        </div>
        <div
            v-else-if="selectedPile === 0">
          <span>{{ $t('addCardsToDiscardPile')}}</span>
        </div>
      </div>
    </div>
    <floating-menu/>
    <icon
        v-if="false"
        class="floating-add"
        icon="plus-circle"
        size="3x"
        @click="$router.push({name: 'addToPile'})"/>
  </div>
</template>

<script>
import Players from "@/components/PlayersPanel";
import Score from '@/components/Score'
import Pile from "@/components/Pile";
import {mapActions, mapGetters} from "vuex";
import CardDetail from "@/components/CardDetail";
import FloatingMenu from "@/components/FloatingMenu";

export default {
  props: {
    pile: {
      type: String,
      default: '1'
    }
  },
  computed: {
    ...mapGetters(['selectedPlayer', 'cardsInSelectedPile', 'selectedCard', 'players', 'selectedPile'])
  },
  methods: {
    ...mapActions(['resetCard', 'selectCard', 'navigateCard']),
    discardSelected(){
      if(this.cardsInSelectedPile.length === 0)
        this.$router.push({name: 'addToPile2'})
    }
  },
  components: {FloatingMenu, Players, Score, Pile, CardDetail}
}
</script>

<style scoped>
.floating-add {
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: space-around;
  bottom: 1rem;
  right: 1rem;
  color: white;
  filter: drop-shadow(1px 1px 4px #000);
  vertical-align: center;
  cursor: pointer;
}

.players-panel {
  position: fixed;
  display: flex;
  height: 10%;
  top: 1%;
  left: 1%;
}

.score {
  position: fixed;
  height: 10%;
  top: 1%;
  right: 3%;
}

.content-wrapper {
  position: relative;
  display: table;
  table-layout: fixed;
  height: 80%;
  max-height: 80%;
  top: 10%;
  width: 100%;
}

.content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 66%;
}

.pile-area {
  position: relative;
  display: flex;
  align-content: center;
  max-width: 90%;
  align-self: center;
}

</style>